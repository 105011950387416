<template>
  <div class="detailCbt">
    <a-modal
      :visible="modalVisible"
      :confirm-loading="confirmLoading"
      @ok="handleOk"
      @cancel="handleCancel"
      :maskStyle="{
        opacity: 0.5,
        backgroundColor: 'black'
      }"
      okText="Update"
      :okButtonProps="{
        props: {
          shape: 'round'
        }
      }"
      :cancelButtonProps="{
        props: {
          shape: 'round'
        }
      }"
    >
      <h3 slot="title" style="color: #faad14;">
        <a-icon style="font-size: 1.2em" type="warning" /> REPORT GRADING CONFIRMATION
      </h3>
      <p>Are you sure to update {{detailReport.kelas}} report grading? Your data before will be lost once you update.</p>
    </a-modal>
    <DrawerReport :visible="visibleDrawer" :dataDrawer="dataDrawer" :chartData="chartData" @on-close="onCloseDrawer" />
    <div class="coverDetail">
      <h2>{{detailReport.kelas}} | {{detailReport.mapel}}</h2>
      <!-- <h4>{{this.detailReport.semester}}</h4> -->
    </div>
    <div class="contentDetail">
      <div class="detail-slot">
        <div class="d-lg-flex mb-3">
            <a-form-item class="d-lg-block">
              <a-radio-group
                @change="handleSelectView"
                :value="selectView"
                button-style="solid"
                size="large"
                :disabled="loadingTable"
              >
                <a-radio-button value="PTS">{{
                  this.textRadio1
                }}</a-radio-button>
                <a-radio-button :value="detailReport.semester === 'Genap' ? 'PAT' : textRadio2">{{detailReport.semester === 'Genap' ? 'PAT' : textRadio2}}</a-radio-button>
                <!-- <a-radio-button :value="(this.detailReport.semester==='Ganjil') ? 'PAS' : 'PAT'">
                  {{(this.detailReport.semester==='Ganjil') ? (this.textRadio2='PAS') : (this.textRadio2='PAT')}}
                </a-radio-button> -->
              </a-radio-group>
            </a-form-item>
            <a-form-item class="ml-auto">
              <a-button
                :disabled="loadingTable"
                @click.prevent="refreshTable"
                type="default"
                style="height: 40px"
                block
              >
                <a-icon type="sync" :spin="loadingRefresh" />Refresh
              </a-button>
            </a-form-item>
          </div>
        <a-row type="flex" justify="space-between">
          <a-col :xs="10" :lg="4">
            <!-- <h3>{{this.selectView}}</h3> -->
            <h3 class="type">{{(this.selectView==='PTS') ? 'Average PH1' : 'Average PH3'}}</h3>
            <p
              class="value"
            >{{(this.selectView==='PTS') ? (detailReport.averagePH1 ? parseFloat(detailReport.averagePH1).toFixed(2) : '-') : (detailReport.averagePH3 ? parseFloat(detailReport.averagePH3).toFixed(2) : '-')}}</p>
          </a-col>
          <a-col :xs="10" :lg="4">
            <h3 class="type">{{(this.selectView==='PTS') ? 'Average PH2' : 'Average PH4'}}</h3>
            <p
              class="value"
            >{{(this.selectView==='PTS') ? (detailReport.averagePH2 ? parseFloat(detailReport.averagePH2).toFixed(2) : '-') : (detailReport.averagePH4 ? parseFloat(detailReport.averagePH4).toFixed(2) : '-')}}</p>
          </a-col>
          <a-col :xs="10" :lg="4">
            <h3 class="type">{{
              (this.selectView==='PTS') ? 'Average PTS' : (this.detailReport.semester==='Ganjil' ? 'Average PAS' : 'Average PAT')
              }}</h3>
            <p
              class="value"
            >{{
              (this.selectView==='PTS') ? (detailReport.averagePTS ? parseFloat(detailReport.averagePTS).toFixed(2) : '-')
              : (this.detailReport.semester==='Ganjil' ? (detailReport.averagePAS ? parseFloat(detailReport.averagePAS).toFixed(2) : '-') : detailReport.averagePAT ? parseFloat(detailReport.averagePAT).toFixed(2) : '-')}}</p>
          </a-col>
          <!-- <a-col :xs="10" :lg="4" class="mt-4 mt-lg-0">
            <h3 class="type">Average PAS</h3>
            <p
              class="value"
            >{{detailReport.averagePAS ? parseFloat(detailReport.averagePAS).toFixed(2) : '-'}}</p>
          </a-col> -->
          <a-col :xs="10" :lg="4" class="mt-4 mt-lg-0">
            <h3 class="type">{{(this.selectView==='PTS') ? 'Average Report' : 'Average Report2'}}</h3>
            <p
              class="value"
            >{{(this.selectView==='PTS') ? (detailReport.averageReport1 ? parseFloat(detailReport.averageReport1).toFixed(2) : '-') : (detailReport.averageReport2 ? parseFloat(detailReport.averageReport2).toFixed(2) : '-')}}</p>
          </a-col>
        </a-row>
        <div class="d-flex flex-column flex-md-row align-items-center my-4">
          <div class="w-100 w-lg-75">
            <a-divider orientation="left">
              <h3>Report Grading</h3>
            </a-divider>
          </div>
          <div class="w-auto d-flex flex-column flex-md-row ml-3">
            <template v-if="!editPH">
              <!-- <a-button
                shape="round"
                icon="select"
                class="mb-3 mb-lg-0 mr-0 mr-md-3"
                >SYNC</a-button
              > -->
              <a-button @click.prevent="toEditPH" shape="round" type="primary" icon="edit">INPUT GRADE</a-button>
            </template>
            <template v-else>
              <a-button
              @click.prevent="cancelEdit"
              :loading="loadingCancel"
              type="danger"
              shape="round"
              icon="close"
              class="mb-3 mb-lg-0 mr-0 mr-md-3"
            >CANCEL</a-button>
            <a-button
              @click.prevent="save"
              shape="round"
              icon="save"
              style="background-color: #41B883; color: white;"
            >SAVE</a-button>
            </template>
          </div>
        </div>
        <a-row type="flex" align="middle" justify="center">
          <a-col :span="24">
            <a-table
              class="table-scroll"
              :pagination="{ pageSize: 30, hideOnSinglePage: true }"
              :columns="handleTableColumn"
              :data-source="dataTable"
              :loading="loadingTable"
              bordered
            >
              <a
                slot="Name"
                class="text-primary"
                slot-scope="text, record"
                @click.prevent="showDrawer(record.key)"
                style="width:120px;"
              >{{ text }}</a>

              <p slot="Notes" slot-scope="text">{{text}}</p>

              <span slot="Score" slot-scope="text">
                <div v-if="text >= 70 && text <= 100">
                  <a-tag color="green">{{ text }}</a-tag>
                </div>
                <div v-else-if="text >= 35 && text <= 69">
                  <a-tag color="orange">{{ text }}</a-tag>
                </div>
                <div v-else-if="text > 0 && text <= 34">
                  <a-tag color="red">{{ text }}</a-tag>
                </div>
              </span>

              <div v-if="editPH" slot="PH1" slot-scope="text, record">
                <a-input
                  :value="text"
                  style="height: 48px; width:80px;"
                  @change="e => handleChange(e.target.value, record.key, 'PH1')"
                />
              </div>
              <div v-else slot="PH1" slot-scope="text">
                <span>{{text}}</span>
              </div>

              <div v-if="editPH" slot="PH2" slot-scope="text, record">
                <a-input
                  :value="text"
                  style="height: 48px; width:80px;"
                  @change="e => handleChange(e.target.value, record.key, 'PH2')"
                />
              </div>
              <div v-else slot="PH2" slot-scope="text">
                <span>{{text}}</span>
              </div>

              <div v-if="editPH" slot="PH3" slot-scope="text, record">
                <a-input
                  :value="text"
                  style="height: 48px; width:80px;"
                  @change="e => handleChange(e.target.value, record.key, 'PH3')"
                />
              </div>
              <div v-else slot="PH3" slot-scope="text">
                <span>{{text}}</span>
              </div>

              <div v-if="editPH" slot="PH4" slot-scope="text, record">
                <a-input
                  :value="text"
                  style="height: 48px; width:80px;"
                  @change="e => handleChange(e.target.value, record.key, 'PH4')"
                />
              </div>
              <div v-else slot="PH4" slot-scope="text">
                <span>{{text}}</span>
              </div>

              <div v-if="editPH" slot="Notes" slot-scope="text, record">
                <a-input
                  :value="text"
                  style="height: 48px; width:250px;"
                  @change="e => handleChange(e.target.value, record.key, 'Notes')"
                />
              </div>
              <div v-else slot="Notes" slot-scope="text">
                <span>{{text}}</span>
              </div>
            </a-table>
          </a-col>
        </a-row>
      </div>
    </div>
  </div>
</template>

<script>
const DrawerReport = () => import('@/components/app/DrawerReport')

const columnPTS = [
  {
    title: 'No',
    // width: '5%',
    // width: 50,
    dataIndex: 'No',
    key: 'No',
  },
  {
    title: 'Name',
    // width: '20%',
    // width: 180,
    dataIndex: 'Name',
    key: 'Name',
    scopedSlots: { customRender: 'Name' },
  },
  {
    title: 'PH1',
    // width: '10%',
    dataIndex: 'PH1',
    key: 'PH1',
    scopedSlots: { customRender: 'PH1' },
  },
  {
    title: 'PH2',
    // width: '10%',
    // width: 50,
    dataIndex: 'PH2',
    key: 'PH2',
    scopedSlots: { customRender: 'PH2' },
  },
  {
    title: 'PTS',
    // width: '10%',
    // width: 50,
    dataIndex: 'PTS',
    key: 'PTS',
    // scopedSlots: { customRender: 'Score' },
  },
  {
    title: 'Report',
    // width: '10%',
    // width: 50,
    dataIndex: 'Report',
    key: 'Report',
    align: 'center',
    scopedSlots: { customRender: 'Score' },
  },
  {
    title: 'Notes',
    // width: '25%',
    // width: 200,
    dataIndex: 'Notes',
    key: 'Notes',
    scopedSlots: { customRender: 'Notes' },
  },
]

const columnPAS = [
  {
    title: 'No',
    // width: '5%',
    // width: 50,
    dataIndex: 'No',
    key: 'No',
  },
  {
    title: 'Name',
    // width: '20%',
    // width: 180,
    dataIndex: 'Name',
    key: 'Name',
    scopedSlots: { customRender: 'Name' },
  },
  {
    title: 'PH3',
    // width: '10%',
    dataIndex: 'PH3',
    key: 'PH3',
    scopedSlots: { customRender: 'PH3' },
  },
  {
    title: 'PH4',
    // width: '10%',
    // width: 50,
    dataIndex: 'PH4',
    key: 'PH4',
    scopedSlots: { customRender: 'PH4' },
  },
  {
    title: 'PAS',
    // width: '10%',
    // width: 50,
    dataIndex: 'PAS',
    key: 'PAS',
    // scopedSlots: { customRender: 'Score' },
  },
  {
    title: 'Report',
    // width: '10%',
    // width: 50,
    dataIndex: 'Report',
    key: 'Report',
    align: 'center',
    scopedSlots: { customRender: 'Score' },
  },
  {
    title: 'Notes',
    // width: '25%',
    // width: 200,
    dataIndex: 'Notes',
    key: 'Notes',
    scopedSlots: { customRender: 'Notes' },
  },
]

const columnPAT = [
  {
    title: 'No',
    // width: '5%',
    // width: 50,
    dataIndex: 'No',
    key: 'No',
  },
  {
    title: 'Name',
    // width: '20%',
    // width: 180,
    dataIndex: 'Name',
    key: 'Name',
    scopedSlots: { customRender: 'Name' },
  },
  {
    title: 'PH3',
    // width: '10%',
    dataIndex: 'PH3',
    key: 'PH3',
    scopedSlots: { customRender: 'PH3' },
  },
  {
    title: 'PH4',
    // width: '10%',
    // width: 50,
    dataIndex: 'PH4',
    key: 'PH4',
    scopedSlots: { customRender: 'PH4' },
  },
  {
    title: 'PAT',
    // width: '10%',
    // width: 50,
    dataIndex: 'PAT',
    key: 'PAT',
    // scopedSlots: { customRender: 'Score' },
  },
  {
    title: 'Report',
    // width: '10%',
    // width: 50,
    dataIndex: 'Report',
    key: 'Report',
    align: 'center',
    scopedSlots: { customRender: 'Score' },
  },
  {
    title: 'Notes',
    // width: '25%',
    // width: 200,
    dataIndex: 'Notes',
    key: 'Notes',
    scopedSlots: { customRender: 'Notes' },
  },
]

export default {
  components: {
    DrawerReport,
  },
  data() {
    return {
      editPH: false,
      modalVisible: false,
      visibleDrawer: false,
      confirmLoading: false,
      loadingCancel: false,
      loadingTable: false,
      columnPTS,
      columnPAS,
      columnPAT,
      dataTable: [],
      dataDetail: {},
      dataDrawer: {},
      chartData: {
        labels: [],
        series: [],
      },
      savedData: [],
      loadingRefresh: false,
      selectView: 'PTS',
      textRadio1: 'PTS',
      textRadio2: 'PAS',
    }
  },
  methods: {
    refreshTable() {
      this.loadingRefresh = true
      if (this.selectView === 'PTS') {
        this.fetchDataDetail()
      } else {
        this.fetchDataDetail()
      }
    },
    handleSelectView(e) {
      const delay = t => new Promise(resolve => setTimeout(resolve, t))
      // console.log('e.target.value', e.target.value)
      if (e.target.value === 'PAS' || e.target.value === 'PAT') {
        this.textRadio2 = 'Please Wait...'
        delay(100).then(() => {
          this.textRadio2 = (this.detailReport.semester === 'Ganjil') ? 'PAS' : 'PAT'
          this.selectView = e.target.value
          this.fetchDataDetail({})
        })
      } else if (e.target.value === 'PTS') {
        this.textRadio1 = 'Please Wait...'
        delay(100).then(() => {
          this.textRadio1 = 'PTS'
          this.selectView = e.target.value
          this.fetchDataDetail({})
        })
      }
    },
    onCloseDrawer(payload) {
      if (payload) {
        this.chartData.labels = []
        this.chartData.series = []
        this.visibleDrawer = false
      }
    },
    showDrawer(key) {
      const newData = [...this.dataTable]
      const target = newData.filter(item => key === item.key)[0]
      // console.log(target)
      const labels = []
      const series = []
      target.ListAssignment.forEach((el, index) => {
        labels.push(`#${++index}`)
        series.push(el.tugas_nilai)
      })

      this.chartData.labels = labels
      this.chartData.series[0] = series
      // console.log(this.chartData)
      this.dataDrawer = target
      this.visibleDrawer = true
    },
    toEditPH() {
      this.editPH = true
    },
    cancelEdit() {
      this.loadingCancel = true
      this.fetchDataDetail()
    },
    handleChange(value, key, column = 'Notes') {
      // console.log('this.selectView', this.selectView)
      const newData = [...this.dataTable]
      const target = newData.filter(item => key === item.key)[0]
      if (target) {
        target[column] = value
        this.savedData = newData
      }
      // console.log('this.savedData', this.savedData)
      // console.log('target', target)
    },
    save() {
      this.modalVisible = true
    },
    validateDataInput(data) {
      let isInvalid = false
      let message = ''
      for (let i = 0; i < data.length; i++) {
        // console.log(data[i])
        if (data[i].PH1 === '-') {
          data[i].PH1 = null
        } else if (data[i].PH2 === '-') {
          data[i].PH2 = null
        }
        if (isNaN(Number(data[i].PH1))) {
          isInvalid = true
          message = `Sorry, ${data[i].Name}'s PH must be numbers. ${i}`
          break
        } else if (Number(data[i].PH1) > 100 || Number(data[i].PH1) < 0) {
          isInvalid = true
          message = `Sorry, ${data[i].Name}'s PH must be between 0 and 100.`
          break
        } else if (isNaN(Number(data[i].PH2))) {
          isInvalid = true
          message = `Sorry, ${data[i].Name}'s PH2 must be numbers. ${i}`
          break
        } else if (Number(data[i].PH2) > 100 || Number(data[i].PH2) < 0) {
          isInvalid = true
          message = `Sorry, ${data[i].Name}'s PH2 must be between 0 and 100.`
          break
        }
      }

      return {
        isInvalid,
        message,
      }
    },
    handleOk() {
      // console.log(this.savedData)
      const newData = [...this.savedData]
      if (newData.length) {
        const validation = this.validateDataInput(newData)
        if (validation.isInvalid) {
          this.$notification.error({
            message: 'Sorry.',
            description: validation.message,
          })
          this.modalVisible = false
        } else {
          const target = newData.map(row => {
            if (this.selectView === 'PTS') {
              return {
                id: row.idReport,
                tipe: this.selectView,
                semester: this.detailReport.semester,
                ph1: row.PH1 ? parseFloat(row.PH1) : 0,
                ph2: row.PH2 ? parseFloat(row.PH2) : 0,
                notes: row.Notes,
              }
            } else {
              return {
                id: row.idReport,
                tipe: this.selectView,
                semester: this.detailReport.semester,
                ph3: row.PH3 ? parseFloat(row.PH3) : 0,
                ph4: row.PH4 ? parseFloat(row.PH4) : 0,
                notes: row.Notes,
              }
            }
          })
          // console.log('Berhasil', target)
          const idMapel = this.$route.params.idMapel
          const idKelas = this.$route.params.idKelas
          this.confirmLoading = true
          this.$store.dispatch('report/PUT_PH', {
            data: target,
            idMapel,
            idKelas,
          })
            .then(res => {
              setTimeout(() => {
                this.fetchDataDetail()
                this.editPH = false
                this.modalVisible = false
                this.confirmLoading = false
                if (res.isSuccess) {
                  this.$notification.success({
                    message: 'Success',
                    description: 'Report Grading has been updated',
                  })
                } else {
                  this.$notification.error({
                    message: 'Sorry',
                    description: 'Report Grading has not been updated',
                  })
                }
              }, 1000)
            })
        }
      } else {
        this.editPH = false
        this.confirmLoading = true
        setTimeout(() => {
          this.$notification.error({
            message: 'Sorry',
            description: 'Report Grading has not been updated',
          })
          this.modalVisible = false
          this.confirmLoading = false
        }, 1000)
      }
    },
    handleCancel() {
      this.modalVisible = false
    },
    fetchDataDetail() {
      const idMapel = this.$route.params.idMapel
      const idKelas = this.$route.params.idKelas
      this.loadingTable = true
      this.$store.dispatch('report/FETCH_DETAIL_RAPORT', { idMapel, idKelas })
        .then(res => {
          this.loadingCancel = false
          this.editPH = false
          this.loadingTable = false
          let no = 1
          // console.log('res', res)
          this.dataTable = res.map(row => {
            // console.log('res', res)
            return {
              key: row.id,
              No: no++,
              idReport: row.idReport,
              id: row.id,
              Name: row.nama,
              PH1: row.PH1 ? parseFloat(row.PH1).toFixed(2) : '',
              PH2: row.PH2 ? parseFloat(row.PH2).toFixed(2) : '',
              PTS: row.PTS ? parseFloat(row.PTS).toFixed(2) : '',
              PH3: row.PH3 ? parseFloat(row.PH3).toFixed(2) : '',
              PH4: row.PH4 ? parseFloat(row.PH4).toFixed(2) : '',
              PAS: row.PAS ? parseFloat(row.PAS).toFixed(2) : '',
              PAT: row.PAT ? parseFloat(row.PAT).toFixed(2) : '',
              Report: (this.selectView === 'PTS') ? (row.report ? parseFloat(row.report).toFixed(2) : '') : (row.report2 ? parseFloat(row.report2).toFixed(2) : ''),
              // Report2: row.report2 ? parseFloat(row.report2).toFixed(2) : '',
              Notes: row.notes,
              TotalAssignment: row.studentsChart.totalAssignment,
              AvgAssignment: row.studentsChart.avgAssignment,
              ListAssignment: row.studentsChart.dataChart,
            }
          })
          this.savedData = this.dataTable
          // console.log(this.dataTable)
        })
    },
  },
  created() {
    this.fetchDataDetail()
  },
  computed: {
    user() {
      return this.$store.state.user.user
    },
    detailReport() {
      return this.$store.state.report.detailReport
    },
    handleTableColumn() {
      let columns
      // console.log(this.selectedMapel)
      if (this.detailReport.semester === 'Ganjil') {
        if (this.selectView === 'PTS') {
          columns = this.columnPTS
        } else if (this.selectView === 'PAS') {
          columns = this.columnPAS
        }
      } else {
        if (this.selectView === 'PTS') {
          columns = this.columnPTS
        } else if (this.selectView === 'PAT') {
          columns = this.columnPAT
        }
      }
      return columns
    },
  },
}
</script>

<style lang="scss">
.detailCbt {
  .coverDetail {
    width: 100%;
    min-height: 145px;
    background-color: #008080;
    border-radius: 10px;
    padding: 40px;
    color: #ffffff;
  }

  h2 {
    color: #ffffff;
  }

  .contentDetail {
    width: 100%;
    min-height: 20vh;
    background-color: white;
    padding: 40px;
    z-index: 11;
    margin-top: -10px;
    border-radius: 10px;
    box-shadow: 0 0 10px #0419301a;
    margin-left: 1px;
  }

  .contentDetail .detail-slot {
    padding: 10px 0;
  }

  .table-scroll {
    .ant-spin-nested-loading .ant-spin-container .ant-table {
      overflow-x: auto;
    }
  }
}
</style>
